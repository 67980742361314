import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import CallInsightsTable from "../../tables/CallInsights/CallInsightsTable";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { insightsByLocation } from "../../redux/Insights/insightsAction";
import dialog from "../../assets/Message Detail Placeholder Icon.svg";

const CallInsights = () => {
  const dispatch = useAppDispatch();

  const { insightsTopicTotal } = useAppSelector((state) => state.farmer);
  const { insightsByLocationData, insightsByLocationLoading } = useAppSelector(
    (state) => state.insights
  );

  useEffect(() => {
    dispatch(insightsByLocation());
  }, [dispatch]);

  return (
    <div>
      <Header title="Call Insights" />

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4 h-full">
        <h4 className="text-[#3E473F] font-medium text-sm">
          Location Breakdown
        </h4>

        {/* TABLE HEADER */}
        <div className="bg-[#F6F7F6] rounded-lg mt-3 p-3 flex gap-3 text-[#5F6D60] text-sm font-medium  ">
          <div className="w-1/3">Location</div>
          <div className="w-1/3">Calls</div>
          <div className="w-1/3">Top insight topic</div>
        </div>
        {/* TABLE DATA */}
        {insightsByLocationLoading ? (
          <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
          </div>
        ) : (
          <>
            {insightsByLocationData.length === 0 ? (
              <div className="flex flex-col min-h-[300px] justify-center items-center h-full w-full">
                <img
                  src={dialog}
                  alt="dialog icon"
                  className="w-[64px] h-[64px] pb-4"
                />
                <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                  You have no insight generated yet
                </p>
              </div>
            ) : (
              <>
                {insightsByLocationData?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  "
                    >
                      <div className="w-1/3 capitalize">
                        {data?.location || "Nill"}
                      </div>
                      <div className="w-1/3">{data?.call_volume || 0}</div>
                      <div className="w-1/3 capitalize">
                        {data?.top_insight_topic?.replace("_", " ") || "Nill"}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}

        {/* <h4 className="text-primary font-medium text-sm cursor-pointer p-3">
          See More
        </h4> */}
      </div>

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4">
        <div>
          <h4 className="text-[#2E332F] text-sm">Insight Topics</h4>

          <h1 className="text-[#2E332F] text-xl font-semibold mt-2">
            {" "}
            {insightsTopicTotal || 0}
          </h1>
        </div>

        <div>
          <CallInsightsTable />
        </div>
      </div>
    </div>
  );
};

export default CallInsights;
