import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts";

export const CustomTooltip = ({
  label,
  payload,
  active,
}: TooltipProps<ValueType, NameType>) => {
  if (active) {
    // console.log(payload);
    return (
      <div className="bg-[#161D17] rounded-lg p-3">
        <p className="text-xs font-semibold text-[#FFFFFF]">{label}</p>

        {payload?.map((data, index) => {
          return (
            <div
              key={index}
              className="flex items-center gap-1 mt-2 justify-between"
            >
              <div className="flex items-center gap-1">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: `${data?.color}`,
                  }}
                ></div>
                <p className="text-xs font-semibold text-[#FFFFFF]">
                  {data?.name}
                </p>
              </div>
              <p className="text-xs font-semibold text-white">
                {data?.value ? data.value.toLocaleString() : "0"}
              </p>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
