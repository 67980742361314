import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  handleSaveInsightDetail,
  insightPayloadType,
} from "../../redux/Insights/insights";
import dialog from "../../assets/Message Detail Placeholder Icon.svg";

const OverviewTable = () => {
  const dispatch = useAppDispatch();
  const { insightsTopic, insightsTopicLoading } = useAppSelector(
    (state) => state.farmer
  );
  const navigate = useNavigate();

  const handleSaveInsightData = (insight: insightPayloadType) => {
    dispatch(handleSaveInsightDetail(insight));
  };

  const slugify = (text: string) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/_/g, " ")
      .trim()
      .replace(/[\s\W-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  return (
    <div>
      {" "}
      <div className="overflow-x-auto rounded-lg mt-5">
        {insightsTopicLoading ? (
          <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
          </div>
        ) : (
          <>
            {insightsTopic.length === 0 ? (
              <div className="flex flex-col min-h-[300px] justify-center items-center h-full w-full">
                <img
                  src={dialog}
                  alt="dialog icon"
                  className="w-[64px] h-[64px] pb-4"
                />
                <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                  You have no insight generated yet
                </p>
              </div>
            ) : (
              <>
                <table className="bg-white  w-full text-left">
                  <thead className=" bg-[#F6F7F6] border border-[#E1E6E1]">
                    <tr className="">
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Topics
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Calls
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Breakdown
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <>
                      {insightsTopic?.map((topic, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleSaveInsightData(topic);
                              navigate(
                                `/call-insights/${slugify(topic?.name)}`
                              );
                            }}
                            key={index}
                            className="border-b border-[#E1E6E1] cursor-pointer"
                          >
                            <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize ">
                              {topic?.name?.replace("_", " ") || "Nill"}
                            </td>
                            <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                              {topic?.total_call_volume || "0"}
                            </td>
                            <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize max-w-[180px]">
                              {topic?.subtopics?.length === 0 ? (
                                <div className="border border-[#96A397] items-center flex w-fit gap-1 px-2 py-1 rounded-lg cursor-not-allowed">
                                  <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                                    Nill
                                  </p>
                                </div>
                              ) : (
                                <div className="flex flex-wrap gap-2">
                                  {topic?.subtopics?.map((subTopic, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="border border-[#96A397] items-center flex gap-1 px-2 py-1 rounded-lg cursor-pointer"
                                      >
                                        <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                                          {`${subTopic?.name?.replace(
                                            "_",
                                            " "
                                          )} `}
                                        </p>
                                        <p className="text-[#5F6D60] text-[11px] font-medium leading-4 ">{`( ${subTopic?.total_call_volume} )`}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
                <div className="px-4 py-4">
                  <Link
                    to="/call-insights"
                    className="text-[#2B8C34] font-medium text-sm"
                  >
                    Show more
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OverviewTable;
