import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
// import FarmerChart from "../../components/Charts/FarmersBarChart";
import ModalComponent from "../../components/Modal/modal";
import Tabs from "../../components/Tabs";
import attach_icon from "../../assets/attach_file_add.svg";
import { Clear } from "@mui/icons-material";
import GoBack from "../../components/GoBack";
import * as XLSX from "xlsx";
import {
  addFarmer,
  getFarmersByOrganization,
  uploadFarmers,
} from "../../redux/Farmers/farmersAction";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import FarmersTable from "../../tables/Farmers/FarmersTable";
import { setProgress } from "../../redux/Farmers/farmers";
import { Link } from "react-router-dom";
import {
  getAllCountries,
  getAllCrops,
  getAllLanguages,
  getAllLivestocks,
  getStateByCountryID,
} from "../../redux/Auth/authAction";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import toast from "react-hot-toast";

interface countryType {
  id: number;
  name: string;
}

interface genderType {
  id: number;
  name: string;
  tag: string;
}

const Farmers = () => {
  const dispatch = useAppDispatch();
  const { profile, crops, livestocks, languages } = useAppSelector((state) => state.auth);
  const { progress } = useAppSelector((state) => state.farmer);
  const { farmersTotal } = useAppSelector((state) => state.farmer);

  const importTabs = [
    { title: "Import from CSV file" },
    { title: "API import" },
  ];
  const addFarmerTabs = [{ title: "Bio" }, { title: "Farm details" }];

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [uploadSteps, setUploadSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failedFarmer, setFailedFarmer] = useState<
    Array<{
      country: string;
      crops: string;
      age: number | string;
      gender: string;
      date_of_birth: string;
      livestock: string;
      crop: string;
      first_name: string;
      language: string;
      last_name: string;
      livestocks: string;
      phone_number: number | null;
      organization: number | null;
      error: {
        status: number;
        code: string;
        messages: {
          field: string;
          message: string;
          rule: string;
        }[];
      };
    }>
  >([
    {
      country: "",
      crops: "",
      age: 0,
      gender: "",
      date_of_birth: "",
      livestock: "",
      crop: "",
      first_name: "",
      language: "",
      last_name: "",
      livestocks: "",
      phone_number: null,
      organization: null,
      error: {
        status: 0,
        code: "string",
        messages: [
          {
            field: "",
            message: "",
            rule: "",
          },
        ],
      },
    },
  ]);
  const [farmerInfo, setFarmerInfo] = useState({
    nameOfFile: "",
    noOfCols: 0,
  });
  const [farmers, setFarmers] = useState<
    Array<{
      country: string;
      crops: string;
      age: number | string;
      first_name: string;
      gender: string;
      date_of_birth: string;
      language: string;
      last_name: string;
      livestocks: string;
      phone_number: number | string | null;
      organization: number | null;
    }>
  >([
    {
      country: "",
      crops: "",
      age: 0,
      first_name: "",
      language: "",
      gender: "",
      date_of_birth: "",
      last_name: "",
      livestocks: "",
      phone_number: null,
      organization: null,
    },
  ]);

  // const [activeLanguage, setActiveLanguage] = useState("7D");
  // const handleLanguageChange = (language: string) => {
  //   setActiveLanguage(language);
  // };

  const [addFarmerDetails, setAddFarmerDetails] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    farm_size: "",
    phone_number: "",
  });

  const [selectedUnit, setSelectedUnit] = useState("M²");

  const { countries, state, countriesLoading, stateLoading } = useAppSelector(
    (state) => state.auth
  );

  const [isSuccess, setIsSuccess] = useState(false);
 
  const [gender] = useState([
    {
      id: 1,
      name: "Male",
      tag: "male",
    },
    {
      id: 2,
      name: "Female",
      tag: "female",
    },
    {
      id: 3,
      name: "Other",
      tag: "other",
    },
  ]);
  const [selectedCountry, setSelectedCountry] = useState<countryType>({
    id: 0,
    name: "",
  });
  const [selectedState, setSelectedState] = useState<countryType>({
    id: 0,
    name: "",
  });
  const [selectedGender, setSelectedGender] = useState<genderType>({
    id: 0,
    name: "",
    tag: "",
  });
  const [selectedLanguage, setSelectedLanguage] = useState<{
    id: number,
    name:string
  }>({
    id: 0,
    name: "",
  });
  const [selectedCrops, setSelectedCrops] = useState<
  {
    id: number;
    name: string;
  }[]>([]);
  const reversedCrops = [...selectedCrops].reverse();
  const [selectedLivestock, setSelectedLivestock] = useState<
  {
    id: number;
    name: string;
  }[]>([]);
  const reversedLivestock = [...selectedLivestock].reverse();
  const [isToggleCountry, setIsToggleCountry] = useState(false);
  const [isToggleState, setIsToggleState] = useState(false);
  const [isToggleGender, setIsToggleGender] = useState(false);
  const [isToggleLanguage, setIsToggleLanguage] = useState(false);
  const [isToggleCrops, setIsToggleCrops] = useState(false);
  const [isToggleLivestock, setIsToggleLivestock] = useState(false);
  const [focusedSelect, setFocusedSelect] = useState<number | null>(null);

  const handleFocus = (index: number) => setFocusedSelect(index);
  const handleBlur = () => setFocusedSelect(null);

  const handleState = (id: number) => {
    const value = { id };
    dispatch(getStateByCountryID(value));
  };

  const validateTab = () => {
    if (tabIndex === 0) {
      // First tab validation logic
      return (
        addFarmerDetails.first_name &&
        addFarmerDetails.last_name &&
        selectedLanguage.name &&
        addFarmerDetails.phone_number &&
        selectedGender.tag &&
        addFarmerDetails.dob &&
        selectedCountry.name &&
        selectedState.name
      );
    } else if (tabIndex === 1) {
      // Second tab validation logic
      return addFarmerDetails.farm_size; // Farm size is provided
    }
    return false;
  };

  const handleTabAddFarmer = (tab: number) => {
    // Optional: Prevent tab switch if fields are incomplete
    if (tabIndex === 0 && !validateTab()) {
      toast.error("Please complete all fields before switching tabs!", {
        style: {
          border: "1px solid #B92043",
          backgroundColor: "#B92043",
          color: "#FFFFFF",
          fontSize: 14,
          maxWidth: 400,
        },
      });
      return;
    }
    setTabIndex(tab);
  };

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllCrops())
    dispatch(getAllLivestocks())
    dispatch(getAllLanguages())
  }, [dispatch]);


  const handleFarmSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddFarmerDetails({
      ...addFarmerDetails,
      farm_size: e.target.value,
    });
  };

  // State to track the selected country code
  const [countryCode, setCountryCode] = useState("");

  // Country options
  const countryOptions = [
    { name: "Nigeria", code: "+234" },
    { name: "Kenya", code: "+254" },
    { name: "Uganda", code: "+256" },
    { name: "Tanzania", code: "+255" },
  ];

  // Handle country change
  const handleCountryChange = (name: string) => {
    let selectedCountryCode = countryOptions.find((o) => o.name === name);
    setCountryCode(selectedCountryCode ? selectedCountryCode.code : "");
  };

  const calculateAge = (birthDateString: string) => {
    const birthDate = new Date(birthDateString); // Convert the birthdate string to a Date object
    const currentDate = new Date(); // Get the current date

    let age = currentDate.getFullYear() - birthDate.getFullYear(); // Calculate the difference in years

    const monthDiff = currentDate.getMonth() - birthDate.getMonth(); // Calculate the month difference

    // If the current date is before the birthdate in the current year, subtract one from age
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleAddFarmer = async () => {
    setLoading(true);

    // Define the country codes
    const countryCodes = {
      Nigeria: "+234",
      Tanzania: "+255",
      Uganda: "+256",
      Kenya: "+254",
    } as const;

    // Get the selected country name
    const countryName = selectedCountry.name as keyof typeof countryCodes;

    // Process the phone number
    let phoneNumber = addFarmerDetails.phone_number;

    if (countryCodes[countryName] && phoneNumber) {
      // Remove leading zero if it exists
      if (phoneNumber.startsWith("0")) {
        phoneNumber = phoneNumber.substring(1);
      }

      // Prepend the country code
      phoneNumber = `${countryCodes[countryName]}${phoneNumber}`;
    }
    const credentials = {
      ...addFarmerDetails,
      farm_size: addFarmerDetails.farm_size + selectedUnit,
      date_of_birth: addFarmerDetails.dob,
      age: calculateAge(addFarmerDetails.dob),
      language: selectedLanguage.name,
      phone_number: phoneNumber,
      gender: selectedGender.tag.toLowerCase(),
      country: selectedCountry.name,
      state: selectedState.name,
      crops: selectedCrops.map((crop) => crop.name).join(','),
      livestocks: selectedLivestock.map((livestock) => livestock.name).join(','),
      organization: profile?.organization?.id,
    };

    dispatch(addFarmer(credentials)).then((res) => {
      if (res?.type === "addFarmer/fulfilled") {
        setLoading(false);
        setAddOpen(false);
        setIsSuccess(true);
        setTabIndex(0);
        setAddFarmerDetails({
          first_name: "",
          last_name: "",
          phone_number: "",
          farm_size: "",
          dob: "",
        });
        setSelectedCountry({
          id: 0,
          name: "",
        });
        setSelectedGender({
          id: 0,
          tag: "",
          name: "",
        });
        setSelectedState({
          id: 0,
          name: "",
        });
        setSelectedCrops([])
        setSelectedLivestock([]);
        setCountryCode("");
        setSelectedUnit("M²");
        dispatch(
          getFarmersByOrganization({
            id: profile?.organization?.id,
            page: 1,
          })
        );
      } else if (res?.type === "addFarmer/rejected") {
        setLoading(false);
      }
    });
  };

  const handleTabChange = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  type FileDetails = {
    name: string;
    size: number;
    columns: number;
    rows: number;
    data: object[]; // Array of objects for parsed data
  };

  const [file, setFile] = useState<FileDetails | null>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);

  // Function to truncate the file name
  const truncateFileName = (name: string, maxLength: number) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop();
      if (fileType === "xlsx" || fileType === "xls" || fileType === "xlsm") {
        const excelData = await parseExcel(selectedFile); // Get array of objects from the Excel file
        if (excelData) {
          const formattedFarmers = excelData.map((farmer: any) => {
            // Extract country code with slicing logic
            const slicedCountryCode =
              farmer.country_code && farmer.country_code.length > 2
                ? farmer.country_code.slice(1, -1) // Removes the first and last characters
                : farmer.country_code || "";
            return {
              country: farmer.country || "",
              state: farmer.state || "",
              crops: farmer.crops || "",
              date_of_birth: farmer.date_of_birth || "",
              age: calculateAge(farmer.date_of_birth) || "",
              farm_size: farmer?.farm_size?.toString() || "",
              first_name: farmer.first_name || "",
              language: farmer.language || "",
              gender: farmer?.gender?.toLowerCase() || "",
              last_name: farmer.last_name || "",
              livestocks: farmer.livestocks || "",
              phone_number:
                slicedCountryCode && farmer.phone_number
                  ? slicedCountryCode === "+234"
                    ? farmer.phone_number.length === 11
                      ? `${slicedCountryCode}${farmer.phone_number.slice(1)}`
                      : farmer.phone_number.length === 10
                      ? `${slicedCountryCode}${farmer.phone_number}`
                      : null
                    : farmer.phone_number.length === 10
                    ? `${slicedCountryCode}${farmer.phone_number.slice(1)}`
                    : `${slicedCountryCode}${farmer.phone_number}`
                  : null,
              organization: profile?.organization?.id,
            };
          });

          console.log(formattedFarmers)

          // Remove objects where only `organization` is filled, and other fields are empty
          const filteredFarmers = formattedFarmers.filter((farmer) => {
            const { organization, ...otherFields } = farmer;
            const otherFieldsEmpty = Object.values(otherFields).every(
              (value) => value === "" || value === null || value === undefined
            );
            return !(otherFieldsEmpty && organization !== null);
          });

          setFarmers(filteredFarmers);
        }
      }
    }
    else {
      toast.error("Invalid file type. Please upload a valid Excel file.", {
        style: {
          border: "1px solid #B92043",
          backgroundColor: "#B92043",
          color: "#FFFFFF",
          fontSize: 14,
          maxWidth: 400,
        },
        position: 'top-right'
      });
    }
  };

  const parseExcel = (file: File): Promise<object[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e:  ProgressEvent<FileReader>) => {
        try {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

          const jsonSheet = XLSX.utils.sheet_to_json(firstSheet, {raw: false}) as object[]; // Array of objects

              // Convert numeric date values to actual dates
            const formattedData = jsonSheet.map((row: any) => {
            if (row.date_of_birth) {
              const dateValue = row.date_of_birth;
              if (!isNaN(dateValue)) {
                // Convert numeric date to readable format (e.g., DD/MM/YYYY)
                const excelEpoch = new Date(1899, 11, 30);
                const actualDate = new Date(excelEpoch.getTime() + (dateValue - 1) * 86400000); // Adjust for Excel epoch and timezone
                row.date_of_birth = actualDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
              }
            }
            return row;
          });

            // Check if the sheet has any data
        if (formattedData && formattedData.length > 0) {
          const rows = formattedData.length;
          const columns = Object.keys(formattedData[0]).length;

          setFarmerInfo({
            nameOfFile: file.name,
            noOfCols: rows,
          });

          setFile({
            name: file.name,
            size: file.size / 1024, // Convert size to KB
            columns,
            rows,
            data: formattedData, // Store the parsed and formatted data
          });
        } else {
          // Handle case where the sheet is empty
          setFarmerInfo({
            nameOfFile: file.name,
            noOfCols: 0,
          });

          setFile({
            name: file.name,
            size: file.size / 1024,
            columns: 0,
            rows: 0,
            data: [], // Empty data
          });
        }

        resolve(formattedData); // Resolve the formatted data
        
        } catch (error) {
          reject(new Error("Failed to parse Excel file."));
        }
      };

      reader.onerror = (error) => {
        reject(new Error("File reading failed."));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const droppedFile = event.dataTransfer.files?.[0];
    if (droppedFile) {
      const fileType = droppedFile.name.split(".").pop();
      if (fileType === "xlsx" || fileType === "xls" || fileType === "xlsm") {
        parseExcel(droppedFile);
      }
    }
  };

  const handleCrops = (data:any) => {
    if (selectedCrops.some((item) => Number(data.id) === Number(item.id))) {
      // eslint-disable-next-line
      // eslint-disable-next-line
      setSelectedCrops(
        // eslint-disable-next-line
        selectedCrops.filter((each) => Number(each.id) != Number(data.id))
      );
      return;
    }
    setSelectedCrops((selServices) => [...selServices, data]);
  };

  const handleLivestock = (data:any) => {
    if (selectedLivestock.some((item) => Number(data.id) === Number(item.id))) {
      // eslint-disable-next-line
      // eslint-disable-next-line
      setSelectedLivestock(
        // eslint-disable-next-line
        selectedLivestock.filter((each) => Number(each.id) != Number(data.id))
      );
      return;
    }
    setSelectedLivestock((selServices) => [...selServices, data]);
  };

  const handleClearFile = () => {
    setFile(null);
  };

  const handleImport = async () => {
    dispatch(setProgress(0));
    setLoading(true);
    setUploadSteps(3);
    const credentials = { farmers: farmers };
    const res = await dispatch(uploadFarmers(credentials));
    if (res?.type === "uploadFarmers/fulfilled") {
      setLoading(false);
      setFarmers([]);
      setUploadSteps(1);
      setIsOpen(false);
      setSuccess(true);
      setFile(null);
      dispatch(
        getFarmersByOrganization({
          id: profile?.organization?.id,
          page: 1,
        })
      );
    }
    if (res?.type === "uploadFarmers/rejected") {
      if(res?.payload?.response?.status === 404) {
         console.log('an error occurred!')
      } else {
        if (Object.keys(res?.payload?.response?.data?.data)?.length === 0) {
          setFailedFarmer([]);
          setUploadSteps(3);
        } else {
        setFailedFarmer(res.payload?.response?.data?.data?.failedFarmers);
        setUploadSteps(4);
       }
      }
      setLoading(false);
      setSuccess(false);
    }
  };

  const handleReupload = () => {
    setFarmers([]);
    setUploadSteps(1);
    setFile(null);
  };

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        allowOutsideClose={false}
        title={
          uploadSteps === 1 ? (
            "Import Farmers"
          ) : (
            <>
              {success ? null : (
                <GoBack
                  handleClick={() => {
                    setUploadSteps(uploadSteps === 2 ? 1 : 2);
                  }}
                />
              )}
            </>
          )
        }
        subtitle=""
        width="max-w-4xl"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div>
          {uploadSteps === 1 && (
            <div>
              <div className="mt-1">
                <Tabs tabs={importTabs} onTabChange={handleTabChange}></Tabs>
              </div>
              {activeTabIndex === 0 && (
                <>
                  <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className="bg-[#F6F7F6] border border-[#E1E6E1] rounded-lg flex justify-center items-center flex-col min-h-[400px]"
                  >
                    <div className="">
                      <div className="flex justify-center">
                        <div className="bg-[#F6F7F6] border border-[#E1E6E1] w-[56px] h-[56px] rounded-full flex justify-center items-center">
                          <img src={attach_icon} alt="attach" />
                        </div>
                      </div>

                      <div className="mt-2 flex justify-center">
                        <p className="text-[#344335] text-sm">
                          {dragActive
                            ? "Release to upload the file"
                            : "Choose a file or drag and drop the file here"}
                        </p>
                      </div>

                      {!file && (
                        <div className="flex justify-center mt-4">
                          <label className="bg-[#2B8C34] disabled:bg-[#ABB6AC] hover:bg-opacity-[0.9] transition-all rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium cursor-pointer">
                            Choose File
                            <input
                              type="file"
                              onChange={handleFileChange}
                              className="hidden"
                              accept=".csv, .xlsx, .xls, .xlsm" // Accept CSV, Excel, and Macro-Enabled Excel files
                            />
                          </label>
                        </div>
                      )}

                      {file && (
                        <div className="border mt-3 min-w-[320px] border-[#E1E6E1] flex flex-col bg-white rounded-lg py-3 px-3">
                          <div className="flex gap-2">
                            <div className="bg-[#F6F7F6] border max-w-[250px] border-[#E1E6E1] flex-grow rounded-lg py-2 px-3">
                              <div className="flex justify-between items-center">
                                <p className="text-[#3E473F] text-xs font-medium">
                                  {truncateFileName(file.name, 24)}{" "}
                                  {/* Truncate the file name */}
                                </p>
                                <p className="text-xs text-[#5F6D60]">
                                  {file.size.toFixed(2)} kb
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={handleClearFile}
                              className="bg-[#F6F7F6] max-w-[38px] flex-auto border cursor-pointer border-[#E1E6E1] flex rounded-lg justify-center items-center"
                            >
                              <Clear
                                style={{ color: "#5F6D60", fontSize: "16px" }}
                              />
                            </div>
                          </div>

                          <div className="mt-3 flex justify-between ">
                            <div>
                              <h6 className="text-[#5F6D60] text-xs">
                                Columns found
                              </h6>
                              <p className="text-sm text-[#181B19] font-medium mt-1">
                                {file.columns}
                              </p>
                            </div>
                            <div>
                              <h6 className="text-[#5F6D60] text-xs">
                                Rows found
                              </h6>
                              <p className="text-sm text-[#181B19] font-medium mt-1">
                                {file.rows}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="text-center mt-5">
                        <Link
                          to="/Sense template.xlsm"
                          target="_blank"
                          download
                        >
                          <button className="text-[#2b8c34] text-sm font-medium">
                            Download CSV format
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end mt-4">
                    <button
                      disabled={file === null || file.rows === 0}
                      onClick={() => setUploadSteps(2)}
                      className="bg-[#2B8C34] disabled:bg-[#ABB6AC] rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium"
                    >
                      Continue
                    </button>
                  </div>
                </>
              )}
              {activeTabIndex === 1 && (
                <>
                  <div className="coming-soon bg-[#F6F7F6] border border-[#E1E6E1] rounded-lg flex justify-center items-center flex-col min-h-[400px]">
                    <p className="font-semibold text-2xl text-center text-[#A7EAAD]">
                      Be patient, it's coming soon
                    </p>
                  </div>
                </>
              )}
            </div>
          )}

          {/* Preview Layout of Csv file uploaded */}
          {uploadSteps === 2 && (
            <div>
              <div className="flex mt-4 gap-3 items-center">
                <div>
                  <h4 className="text-[#161D17] font-medium text-lg">
                    Import preview
                  </h4>
                </div>
                <div className="bg-[#F6F7F6] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#5F6D60] text-xs font-medium">
                    {farmerInfo?.nameOfFile || "N/A"}
                  </p>
                </div>
                <div className="bg-[#F6F7F6] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#5F6D60] text-xs font-medium">
                    {" "}
                    {farmerInfo?.noOfCols || "N/A"} Rows
                  </p>
                </div>
              </div>

              <div className="overflow-x-auto border-x border-t border-t-[#E1E6E1]   border-x-[#E1E6E1] rounded-lg mt-5">
                <table className="bg-white rounded-lg w-full text-left">
                  <thead className=" bg-[#F6F7F6]  rounded-lg">
                    <tr className="">
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Phone Number
                      </th>
                      {/* <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Gender
                      </th> */}
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Country
                      </th>
                      {/* <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Province
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example row */}
                    {farmers?.map((farmer, index) => {
                      return (
                        <tr key={index} className="border-b border-[#E1E6E1]">
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize ">
                            {farmer?.first_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.last_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.phone_number || "Nill"}
                          </td>
                          {/* <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.gender || "Nill"}
                          </td> */}
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.country || "Nill"}
                          </td>
                          {/* <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.state || "Nill"}
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-end mt-4">
                <button
                  onClick={handleImport}
                  disabled={loading || farmers.length === 0}
                  className="bg-[#2B8C34] disabled:bg-[#ABB6AC] rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium"
                >
                  {loading ? "Loading..." : "Start Import"}
                </button>
              </div>
            </div>
          )}

          {/*Importing*/}
          {uploadSteps === 3 && (
            <div>
              <div className="flex mt-4 gap-3 items-center">
                <div>
                  <h4 className="text-[#161D17] font-medium text-lg">
                    Importing
                  </h4>
                </div>
                <div className="bg-[#F6F7F6] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#5F6D60] text-xs font-medium">
                    {farmerInfo?.nameOfFile || "N/A"}
                  </p>
                </div>
              </div>

              <div className="border border-[#E1E6E1] rounded-lg mt-[20px] p-3">
                <div className="flex items-center justify-between">
                  <p className="text-[#5F6D60] font-normal text-xs">
                    Importing...
                  </p>

                  <p className="text-[#5F6D60] font-normal text-xs">
                    {farmerInfo?.noOfCols}
                  </p>
                </div>
                <div className="mt-2 w-full h-[36px] bg-[#F6F7F6] rounded-lg relative overflow-hidden">
                  <div
                    style={{ width: `${progress}%` }}
                    className="h-full bg-primary absolute z-[10] top-0 left-0 flex items-center px-3 rounded-lg transition-width duration-300 ease-in-out"
                  />
                  <div className="h-full bg-[#E1E6E1]  top-0 z-[20] left-0 flex items-center px-3 rounded-lg transition-width duration-300 ease-in-out">
                    <p className="text-white font-medium leading-4 text-xs z-10">
                      Oyo state farmers temporary farmers
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*Failed Farmer*/}
          {uploadSteps === 4 && (
            <div>
              <div className="flex mt-4 gap-3 items-center">
                <div>
                  <h4 className="text-[#161D17] font-medium text-lg">
                    Failed upload
                  </h4>
                </div>
                <div className="bg-[#FBB7AB] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#B92020] text-xs font-medium">
                    {failedFarmer?.length || 0} rows
                  </p>
                </div>
              </div>

              <div className="overflow-x-auto w-full border-x border-t border-t-[#E1E6E1]   border-x-[#E1E6E1] rounded-lg mt-5">
                <table className="bg-white overflow-x-auto rounded-lg w-full text-left min-w-[800px]">
                  <thead className=" bg-[#F6F7F6]  rounded-lg">
                    <tr className="">
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Phone Number
                      </th>

                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Age
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Crop
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Livestock
                      </th>

                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Country
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px] whitespace-nowrap w-[300px]"
                      >
                        Reason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example row */}
                    {failedFarmer?.map((farmer, index) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-[#E1E6E1] w-full"
                        >
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize ">
                            {farmer?.first_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.last_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.phone_number || "Nill"}
                          </td>

                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.age || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.crops || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.livestocks || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.country || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize whitespace-normal w-[300px]">
                            {farmer?.error?.messages[0]?.message || "Nill"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-end items-end">
                <button
                  className="px-5 py-[10px] border rounded-lg bg-primary outline-primary text-white mt-6 text-sm font-normal disabled: hover: focus:"
                  onClick={() => handleReupload()}
                >
                  Reupload
                </button>
              </div>
            </div>
          )}
        </div>
      </ModalComponent>

      <ModalComponent
        isOpen={addOpen}
        allowOutsideClose={false}
        title={"Add Farmer"}
        subtitle=""
        width="max-w-3xl"
        onClose={() => {
          setAddOpen(false);
        }}
      >
        <div>
          <div>
            <div className="mt-1 mb-4">
              <ul className="flex gap-4 items-center">
                {addFarmerTabs.map((tab, index) => (
                  <li
                    key={index}
                    className={`text-xs py-1 font-medium cursor-pointer ${
                      tabIndex === index
                        ? "text-primary border-b-2 border-primary"
                        : "text-[#96A397]"
                    }`}
                    onClick={() => handleTabAddFarmer(index)} // Update active tab
                  >
                    {tab.title}
                  </li>
                ))}
              </ul>
            </div>
            {tabIndex === 0 && (
              <>
                <div>
                  <form className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="block font-medium text-[#344335] text-sm mb-2"
                        >
                          First name
                        </label>
                        <input
                          type="text"
                          className="w-full rounded-lg border focus:border-primary focus:shadow-1xl  border-[#96A397] outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm font-normal leading-[18px]"
                          min={3}
                          value={addFarmerDetails.first_name}
                          onChange={(e) =>
                            setAddFarmerDetails({
                              ...addFarmerDetails,
                              first_name: e.target.value,
                            })
                          }
                          placeholder={"First name"}
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor="last-name"
                          className="block font-medium text-[#344335] text-sm mb-2"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          className="w-full rounded-lg border focus:border-primary focus:shadow-1xl  border-[#96A397] outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm leading-[18px]"
                          min={3}
                          value={addFarmerDetails.last_name}
                          onChange={(e) =>
                            setAddFarmerDetails({
                              ...addFarmerDetails,
                              last_name: e.target.value,
                            })
                          }
                          placeholder="Last name"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="w-full">
                        <label
                          htmlFor="country"
                          className="block mb-2 font-medium text-[#344335] text-sm"
                        >
                          Country
                        </label>
                        <div
                          tabIndex={1}
                          onFocus={() => handleFocus(1)}
                          onBlur={handleBlur}
                          onClick={() => {
                            setIsToggleCountry((prev) => !prev);
                          }}
                          className={`flex items-center px-4 py-[12px] cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                            focusedSelect === 1
                              ? "focus:border-primary focus:shadow-1xl "
                              : ""
                          }`}
                        >
                          <p className="text-sm leading-[18px] text-[#344335]">
                            {selectedCountry?.name || "Select country"}
                          </p>
                          {isToggleCountry ? (
                            <ExpandLess
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          )}
                        </div>

                        {isToggleCountry && (
                          <div className="relative">
                            <div className="absolute w-full top-1 left-1">
                              <div className="rounded-[10px] bg-white p-3 w-full h-full shadow z-10  overflow-y-scroll  max-h-[248px]">
                                {countriesLoading ? (
                                  <div className="h-[100px] flex justify-center items-center">
                                    <p className="text-sm font-medium leading-[18px]  ">
                                      Loading...
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {countries?.slice(0,4).map((data, index) => {
                                      return (
                                        <div
                                          className={`p-3 ${
                                            index < countries.length-1
                                              ? "mb-[4px]"
                                              : ""
                                          } ${
                                            selectedCountry.name === data.name
                                              ? "border border-primary bg-[#EDF7EE] text-primary rounded-lg cursor-default "
                                              : "text-[#344335] hover:bg-[#EDF7EE] hover:rounded-lg cursor-pointer "
                                          }`}
                                          onClick={() => {
                                            setSelectedCountry(data);
                                            handleState(data?.id);
                                            handleCountryChange(data.name);
                                            setIsToggleCountry(false);
                                          }}
                                          key={index}
                                        >
                                          <p className="text-sm font-medium leading-[18px] ">
                                            {data.name}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="state-province"
                          className="block mb-2 font-medium text-[#344335] text-sm "
                        >
                          State/province
                        </label>
                        <div
                          tabIndex={2}
                          onFocus={() => handleFocus(2)}
                          onBlur={handleBlur}
                          onClick={() => {
                            if (selectedCountry.name !== "") {
                              setIsToggleState((prev) => !prev);
                            } else {
                              toast.error("Select a country first", {
                                style: {
                                  border: "1px solid #B92043",
                                  backgroundColor: "#B92043",
                                  color: "#FFFFFF",
                                  fontSize: 14,
                                  maxWidth: 400,
                                },
                              });
                            }
                          }}
                          className={`flex items-center px-4 py-[12px] cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                            focusedSelect === 2
                              ? "focus:border-primary focus:shadow-1xl "
                              : ""
                          }`}
                        >
                          <p className="text-sm leading-[18px] text-[#344335]">
                            {selectedState?.name || "Select state/province"}
                          </p>
                          {isToggleState ? (
                            <ExpandLess
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          )}
                        </div>

                        {isToggleState && (
                          <div className="relative w-full">
                            <div className="absolute top-1 w-full left-1">
                              <div className="rounded-[10px] bg-white p-3 h-full overflow-y-scroll select_scroll max-h-[248px] shadow z-10">
                                {stateLoading ? (
                                  <div className="h-[100px] flex justify-center items-center">
                                    <p className="text-sm font-medium leading-[18px]  ">
                                      Loading...
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {state?.map((data, index) => {
                                      return (
                                        <div
                                          className={`p-3 
                                             ${
                                            index < state.length-1
                                              ? "mb-[4px]"
                                              : ""
                                          }  ${
                                            selectedState.name === data.name
                                              ? "border border-primary bg-[#EDF7EE] text-primary rounded-lg cursor-default "
                                              : "text-[#344335] hover:bg-[#EDF7EE] hover:rounded-lg cursor-pointer "
                                          }`}
                                          onClick={() => {
                                            setSelectedState(data);
                                            setIsToggleState(false);
                                          }}
                                          key={index}
                                        >
                                          <p className="text-sm font-medium leading-[18px] ">
                                            {data.name}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">
                        <label
                          htmlFor="phone_number"
                          className="block font-medium text-[#344335] text-sm mb-2"
                        >
                          Phone number
                        </label>
                        <div className="flex gap-1 items-center">
                          {/* Country Code */}
                          <div className="bg-[#F6F7F6] px-4 py-3 flex justify-center max-h-[44px] items-center rounded-lg  border border-[#96A397]">
                            <p className="text-sm font-medium text-[#96A397]">
                              {countryCode ? countryCode : "+000"}
                            </p>
                          </div>

                          <div className="flex-grow">
                            <input
                              type="text"
                              className="w-full rounded-lg border focus:border-primary focus:shadow-1xl  border-[#96A397] outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm font-normal leading-[18px]"
                              min={3}
                              value={addFarmerDetails.phone_number}
                              onChange={(e) =>
                                setAddFarmerDetails({
                                  ...addFarmerDetails,
                                  phone_number: e.target.value,
                                })
                              }
                              placeholder={"Phone number"}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="country"
                          className="block mb-2 font-medium text-[#344335] text-sm"
                        >
                          Language
                        </label>
                        <div
                          tabIndex={1}
                          onFocus={() => handleFocus(1)}
                          onBlur={handleBlur}
                          onClick={() => {
                            setIsToggleLanguage((prev) => !prev);
                          }}
                          className={`flex items-center px-4 py-[12px] cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                            focusedSelect === 1
                              ? "focus:border-primary focus:shadow-1xl "
                              : ""
                          }`}
                        >
                          <p className="text-sm leading-[18px] text-[#344335]">
                            {selectedLanguage?.name || "Select Language"}
                          </p>
                          {isToggleLanguage ? (
                            <ExpandLess
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          )}
                        </div>

                        {isToggleLanguage && (
                          <div className="relative">
                            <div className="absolute w-full top-1 left-1">
                              <div className="rounded-[10px] bg-white p-3 w-full h-full shadow z-10 overflow-y-scroll  max-h-[248px] ">
                                <>
                                  {languages?.map((data, index) => {
                                    return (
                                      <div
                                        className={`p-3 ${
                                          index < languages.length-1
                                            ? "mb-[4px]"
                                            : ""
                                        } ${
                                          selectedLanguage.name === data.name
                                            ? "border border-primary bg-[#EDF7EE] text-primary rounded-lg cursor-default "
                                            : "text-[#344335] hover:bg-[#EDF7EE] hover:rounded-lg cursor-pointer "
                                        }`}
                                        onClick={() => {
                                          setSelectedLanguage(data);
                                          setIsToggleLanguage(false);
                                        }}
                                        key={index}
                                      >
                                        <p className="text-sm font-medium leading-[18px] ">
                                          {data.name}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                    <div className="grid grid-cols-2 gap-4 ">
                      <div className="w-full">
                        <label
                          htmlFor="country"
                          className="block mb-2 font-medium text-[#344335] text-sm"
                        >
                          Gender
                        </label>
                        <div
                          tabIndex={1}
                          onFocus={() => handleFocus(1)}
                          onBlur={handleBlur}
                          onClick={() => {
                            setIsToggleGender((prev) => !prev);
                          }}
                          className={`flex items-center px-4 py-[12px] cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                            focusedSelect === 1
                              ? "focus:border-primary focus:shadow-1xl "
                              : ""
                          }`}
                        >
                          <p className="text-sm leading-[18px] text-[#344335]">
                            {selectedGender?.name || "Select gender"}
                          </p>
                          {isToggleGender ? (
                            <ExpandLess
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          )}
                        </div>

                        {isToggleGender && (
                          <div className="relative">
                            <div className="absolute w-full top-1 left-1">
                              <div className="rounded-[10px] bg-white p-3 w-full h-full shadow z-10">
                                <>
                                  {gender?.map((data, index) => {
                                    return (
                                      <div
                                        className={`p-3 ${
                                          index < gender.length-1
                                            ? "mb-[4px]"
                                            : ""
                                        } ${
                                          selectedGender.name === data.name
                                            ? "border border-primary bg-[#EDF7EE] text-primary rounded-lg cursor-default "
                                            : "text-[#344335] hover:bg-[#EDF7EE] hover:rounded-lg cursor-pointer "
                                        }`}
                                        onClick={() => {
                                          setSelectedGender(data);
                                          setIsToggleGender(false);
                                        }}
                                        key={index}
                                      >
                                        <p className="text-sm font-medium leading-[18px] ">
                                          {data.name}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="">
                        <label
                          htmlFor="age"
                          className="block mb-2 font-medium text-[#344335] text-sm"
                        >
                          Date of birth
                        </label>
                        <input
                          type="date"
                          className="w-full rounded-lg border focus:border-primary focus:shadow-1xl  border-[#96A397] outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm  leading-[18px]"
                          value={addFarmerDetails.dob}
                          onChange={(e) =>
                            setAddFarmerDetails({
                              ...addFarmerDetails,
                              dob: e.target.value,
                            })
                          }
                          max={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 18
                              )
                            )
                              .toISOString()
                              .split("T")[0]
                          } // Calculate max date for age > 18
                          placeholder="Age (in numbers)"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {tabIndex === 1 && (
              <div className="">
                <div className="">
                  <div className="">
                    <label
                      htmlFor="farm-size"
                      className="font-medium text-[#344335] text-sm block mb-2"
                    >
                      Farm size
                    </label>
                    <div className="flex items-center gap-2">
                      <div className="flex-1">
                        <input
                          type="text"
                          value={addFarmerDetails.farm_size}
                          onChange={handleFarmSizeChange}
                          className="w-full rounded-lg border focus:border-primary focus:shadow-1xl  border-[#96A397] outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm leading-[18px]"
                          placeholder="Size"
                        />
                      </div>

                      {/* Unit Selector */}
                      <div className="flex border border-[#96A397] rounded-lg overflow-hidden text-sm max-h-[44px]">
                        {["M²", "Ha", "Acres"].map((unit, index) => (
                          <button
                            key={unit}
                            onClick={() => setSelectedUnit(unit)}
                            className={`px-4 py-3 ${
                              index < ["M²", "Ha", "Acres"].length - 1
                                ? "border-r border-[#96A397]"
                                : ""
                            }  text-sm font-medium ${
                              selectedUnit === unit
                                ? "bg-[#E1F7E3] text-[#2B8C34] font-medium"
                                : "bg-[#FFFFFF] text-[#96A397]"
                            }`}
                          >
                            {unit}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                  
                <div className="grid grid-cols-2 gap-4 mt-4">
                   
                    <div className="w-full">
                        <label
                          htmlFor="country"
                          className="block mb-2 font-medium text-[#344335] text-sm"
                        >
                          Crop(s)
                        </label>
                        <div
                          tabIndex={1}
                          onFocus={() => handleFocus(1)}
                          onBlur={handleBlur}
                          onClick={() => {
                            setIsToggleCrops((prev) => !prev);
                          }}
                          className={`flex items-center ${selectedCrops.length ? 'py-2 px-3' : 'py-3 px-4'} cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                            focusedSelect === 1
                              ? "focus:border-primary focus:shadow-1xl "
                              : ""
                          }`}
                        >
                        <div className="flex gap-2 flex-wrap">
                        {selectedCrops.length ? (
                            <>
                              {reversedCrops.slice(0, 3).map((crop, index) => (
                                <div key={index} className="">
                                  <p className="text-[#1D5D23] bg-[#DBFFDF] rounded-[20px] py-1 px-3 text-sm font-medium">
                                    {crop.name}
                                  </p>
                                </div>
                              ))}
                              {reversedCrops.length > 3 && (
                                <div>
                                  <p className="text-[#1D5D23] bg-[#DBFFDF] rounded-[20px] py-1 px-3 text-sm font-medium">
                                    +{reversedCrops.length - 3}
                                  </p>
                                </div>
                              )}
                            </>
                          ) : (
                            <p className="text-sm text-[#344335]">Select Crops</p>
                          )}
                         </div>
                         
                          {isToggleCrops ? (
                            <ExpandLess
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          )}
                        </div>

                        {isToggleCrops && (
                          <div className="relative">
                            <div className="absolute w-full top-1 left-1">
                              <div className="rounded-[10px] bg-white p-3 w-full h-full shadow z-10 overflow-y-scroll  max-h-[248px] ">
                                <>
                                  {crops?.map((data, index) => {
                                    return (
                                      <div
                                        className={`p-3 text-[#344335] hover:bg-[#EDF7EE] flex gap-4 items-center hover:rounded-lg cursor-pointer`}
                                        key={index}
                                      >
                                          <input
                                          type="checkbox"
                                          checked={selectedCrops.some(
                                            (item) => Number(item.id) === Number(data.id)
                                          )}
                                          onChange={(event) => {
                                            handleCrops(data);
                                          }}
                                          className="accent-primary h-[14px] w-[14px] rounded-[2px] cursor-pointer"
                                        />
                                        <p className="text-sm font-medium">
                                          {data.name}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="country"
                          className="block mb-2 font-medium text-[#344335] text-sm"
                        >
                          Livestock(s)
                        </label>
                        <div
                          tabIndex={1}
                          onFocus={() => handleFocus(1)}
                          onBlur={handleBlur}
                          onClick={() => {
                            setIsToggleLivestock((prev) => !prev);
                          }}
                          className={`flex items-center ${selectedLivestock.length ? 'py-2 px-3' : 'py-3 px-4'} cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                            focusedSelect === 1
                              ? "focus:border-primary focus:shadow-1xl "
                              : ""
                          }`}
                        >
                        <div className="flex gap-2 flex-wrap">
                        {selectedLivestock.length ? (
                            <>
                              {reversedLivestock.slice(0, 3).map((livestock, index) => (
                                <div key={index} className="">
                                  <p className="text-[#1D5D23] bg-[#DBFFDF] rounded-[20px] py-1 px-3 text-sm font-medium">
                                    {livestock.name}
                                  </p>
                                </div>
                              ))}
                              {reversedLivestock.length > 3 && (
                                <div>
                                  <p className="text-[#1D5D23] bg-[#DBFFDF] rounded-[20px] py-1 px-3 text-sm font-medium">
                                    +{reversedLivestock.length - 3}
                                  </p>
                                </div>
                              )}
                            </>
                          ) : (
                            <p className="text-sm text-[#344335]">Select Livestocks</p>
                          )}
                         </div>
                         
                          {isToggleLivestock ? (
                            <ExpandLess
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ color: "#96A397", fontSize: "20px" }}
                            />
                          )}
                        </div>

                        {isToggleLivestock && (
                          <div className="relative">
                            <div className="absolute w-full top-1 left-1">
                              <div className="rounded-[10px] bg-white p-3 w-full h-full shadow z-10 overflow-y-scroll  max-h-[248px] ">
                                <>
                                  {livestocks?.map((data, index) => {
                                    return (
                                      <div
                                        className={`p-3 text-[#344335] hover:bg-[#EDF7EE] flex gap-4 items-center hover:rounded-lg cursor-pointer`}
                                        key={index}
                                      >
                                          <input
                                          type="checkbox"
                                          checked={selectedLivestock.some(
                                            (item) => Number(item.id) === Number(data.id)
                                          )}
                                          onChange={(event) => {
                                            handleLivestock(data);
                                          }}
                                          className="accent-primary h-[14px] w-[14px] rounded-[2px] cursor-pointer"
                                        />
                                        <p className="text-sm font-medium">
                                          {data.name}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                  

                </div>

                
              </div>
            )}
            <div className="flex justify-end mt-6">
              {tabIndex === 0 ? (
                <button
                  disabled={!validateTab()}
                  onClick={() => setTabIndex(1)}
                  className="bg-[#2B8C34] disabled:bg-[#ABB6AC] rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium"
                >
                  Continue
                </button>
              ) : (
                <button
                  disabled={!validateTab() || loading} // Second tab validation
                  onClick={handleAddFarmer}
                  className="bg-[#2B8C34] disabled:bg-[#ABB6AC] rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium"
                >
                  Add Farmer
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        isOpen={isSuccess}
        allowOutsideClose={true}
        title={"Farmer added"}
        subtitle=""
        onClose={() => {
          setIsSuccess(false);
        }}
      >
        <>
          <p className="text-[#5C715E] font-medium mt-3 text-sm">
            Farmer has been added to the list
          </p>
          <div className="flex justify-end mt-10">
            <button
              onClick={() => {
                setIsSuccess(false);
              }}
              className=" rounded-md font-medium text-sm text-[#5C715E] text-right"
            >
              Close
            </button>
          </div>
        </>
      </ModalComponent>
      <Header
        title="Farmers"
        btn={
          <>
            <div className="space-x-4">
              <button
                className="px-5 py-[10px] border rounded-lg bg-white outline-primary text-primary text-sm font-normal disabled: hover: focus:"
                onClick={() => setIsOpen(true)}
              >
                Import
              </button>
              <button
                className="px-5 py-[10px] rounded-lg bg-primary text-white text-sm font-normal disabled: hover: focus:"
                onClick={() => setAddOpen(true)}
              >
                Add Farmer
              </button>
            </div>
          </>
        }
      ></Header>
      <div className="mt-3 h-full">
        <div className="flex justify-between">
          <div className="flex gap-10 items-center">
            <div className="flex flex-col gap-2 py-4 w-[264px] bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4 ">
              <p className="text-[#2E332F] text-sm">Farmers</p>

              <div>
                <h4 className="text-[#2E332F] font-semibold text-2xl">
                  {farmersTotal || 0}
                </h4>

                {/* <div className="mt-1">
                  <p className="text-primary text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div> */}
              </div>
            </div>

            {/* <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <div className="bg-[#2B8C34] w-[8px] h-[8px] rounded-full"></div>
                  <div>
                    <p className="text-[#2E332F] text-sm">Calls to NHFA</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <h4 className="text-[#2B8C34] font-semibold text-xl">
                    32,342
                  </h4>
                </div>
                <div className="mt-1">
                  <p className="text-[#2B8C34] text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className="flex h-fit items-center cursor-pointer justify-center bg-[#F7F7F7] rounded-[8px] border border-[#ECEFEC]">
            {["7D", "1M", "3M", "1Y"].map((lang, index) => (
              <div
                key={index}
                onClick={() => handleLanguageChange(lang)}
                className={`cursor-pointer px-4 py-3 text-sm font-medium flex items-center justify-center 
                ${
                  activeLanguage === lang
                    ? "bg-white text-[#161D17] border border-[#ECEFEC] rounded-[8px] shadow-2xl" // Active language style
                    : "bg-transparent text-[#5C715E]"
                }`} // Default language style
              >
                <span>{lang}</span>
              </div>
            ))}
          </div> */}
        </div>

        {/* <div className="mt-10">
          <FarmerChart data={graphData} height={400} />
        </div> */}
      </div>
      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4 pt-4 w-full space-y-2">
        <FarmersTable />
      </div>
    </>
  );
};

export default Farmers;
