import GoBack from "../../components/GoBack";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import {
  ContentCopy,
  ForumOutlined,
  DateRange,
  Search,
  Person
} from "@mui/icons-material";
import sense from "../../assets/sense.svg";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { getFarmersProfile } from "../../redux/Farmers/farmersAction";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  insightsConversation,
  insightsGraph,
} from "../../redux/Insights/insightsAction";
import { summaryType } from "../../redux/Insights/insights";
import dialog from "../../assets/Message Detail Placeholder Icon.svg";

const FarmerProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { farmerProfile, farmersLoading } = useAppSelector(
    (state) => state.farmer
  );
  const { conversationSummaryLoading, conversationSummary, conversationSummaryTotal } = useAppSelector(
    (state) => state.insights
  );
  const { id } = useParams();
  const [isToggleShowMore, setIsToggleShowMore] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState<summaryType>({
    farmer: {
      id: 0,
      name: "",
      phone_number: "",
    },
    snippet: "",
    timestamp: {
      date: "",
      time: "",
    },
    conversation_details: [
      {
        sender: "",
        audio_url: "",
        timestamp: "",
        farmer_question: "",
      },
    ],
  });

  const cropList = farmerProfile?.crop?.split(", ") || []; // Split the crops string into an array
  const visibleCrops = cropList.slice(0, 2); // Show only the first two crops
  const remainingCrops = cropList.slice(2); // Get the remaining crops for the tooltip

  const livestockList = farmerProfile?.livestock?.split(", ") || []; // Split the crops string into an array
  const visibleLivestock = livestockList.slice(0, 2); // Show only the first two crops
  const remainingLivestock = livestockList.slice(2); // Get the remaining crops for the tooltip

  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const [conversationSumm, setConversationSumm] = useState<summaryType[]>([]);

  const handleLoadMore = () =>{
    setLoader(true)
    const nextPage = currentPage + 1;
     dispatch(insightsConversation({ id: 0, farmerId: Number(id), page: nextPage })).then((res)=>{
      if(res?.type === "insightsConversation/fulfilled"){
        setLoader(false)
        const newConversations = res?.payload?.results;

        // Append new conversations to the existing list
        setConversationSumm((prevConversations) => [...prevConversations, ...newConversations])
        setCurrentPage(nextPage)
      }
      else if(res?.type === "insightsConversation/rejected"){
        setLoader(false)
      }
    });
  }

  useEffect(() => {
    // Sync local state with Redux state only when currentPage is 1
    if (currentPage === 1 && conversationSummary) {
      setConversationSumm(conversationSummary);
    }
  }, [conversationSummary, currentPage]);


  const [typeId, setTypeID] = useState(1);
  const type = [
    {
      id: 1,
      title: "Profile",
    },
    {
      id: 2,
      title: "Insights",
    },
  ];

  const toggleType = (id: number) => {
    setTypeID(id);
  };

  const handleCopyNumber = (number: string) => {
    navigator.clipboard.writeText(number);
    toast.success(`${number} copied to clipboard`, {
      style: {
        border: "1px solid #2B8C34",
        backgroundColor: "#2B8C34",
        color: "#FFFFFF",
        fontSize: 14,
      },
      position: "top-center",
    });
  };

  const hasFarmCharacteristics = farmerProfile?.farm_characteristics &&
  (farmerProfile.farm_characteristics.location_details ||
    farmerProfile.farm_characteristics.farm_size ||
    farmerProfile.farm_characteristics.livestock ||
    farmerProfile.farm_characteristics.farming_methods ||
    farmerProfile.farm_characteristics.equipment ||
    farmerProfile.farm_characteristics.infrastructure?.values ||
    farmerProfile.farm_characteristics.infrastructure?.size);

    const hasBusinessContext = farmerProfile?.business_context && (
      farmerProfile.business_context.market_connections?.values ||
      (Array.isArray(farmerProfile.business_context.distribution_channels?.values) &&
        farmerProfile.business_context.distribution_channels.values.length > 0) ||
      farmerProfile.business_context.processing_capabilities?.values ||
      farmerProfile.business_context.storage_facilities?.values ||
      (Array.isArray(farmerProfile.business_context.certifications_held?.values) &&
        farmerProfile.business_context.certifications_held.values.length > 0)
    );

    const hasKnowledgeAndExperience = farmerProfile?.knowledge_and_experience && (
      farmerProfile.knowledge_and_experience.years_farming_experience ||
      (Array.isArray(farmerProfile.knowledge_and_experience.technical_expertise_areas) &&
        farmerProfile.knowledge_and_experience.technical_expertise_areas.length > 0) ||
      farmerProfile.knowledge_and_experience.training_education_background?.level ||
      farmerProfile.knowledge_and_experience.technology_adoption_level?.description ||
      (Array.isArray(farmerProfile.knowledge_and_experience.previous_crop_history?.values) &&
        farmerProfile.knowledge_and_experience.previous_crop_history.values.length > 0)
    );

    const hasTechnicalParameters = farmerProfile?.technical_parameters && (
      farmerProfile.technical_parameters.soil_type ||
      farmerProfile.technical_parameters.conditions?.ph_level ||
      farmerProfile.technical_parameters.water_sources_and_availability ||
      farmerProfile.technical_parameters.growing_season?.duration ||
      farmerProfile.technical_parameters.yield_data?.maize ||
      farmerProfile.technical_parameters.yield_data?.cassava ||
      farmerProfile.technical_parameters.yield_data?.groundnuts ||
      farmerProfile.technical_parameters.chemical_fertilizer_usage
    );

    const hasAgriculturalPractices = farmerProfile?.agricultural_practices && (
      farmerProfile.agricultural_practices.cultivation_techniques ||
      farmerProfile.agricultural_practices.irrigation_methods ||
      farmerProfile.agricultural_practices.pest_management_approaches ||
      farmerProfile.agricultural_practices.soil_management_practices ||
      farmerProfile.agricultural_practices.post_harvest_practice
    );

  useEffect(() => {
    dispatch(getFarmersProfile({ id: Number(id) }));
    dispatch(insightsGraph({ id: 0, farmerId: Number(id), }));
    dispatch(insightsConversation({ id: 0, farmerId: Number(id), page: 1 }));
  }, [dispatch, id]);

  return (
    <div>
      <>
        <div className="p-3">
          <GoBack handleClick={() => navigate(-1)} />
        </div>

        <div>
          <Header title="Farmer account">
            <div className="mt-4">
              <ul className="flex gap-4 items-center">
                {type.map((val) => (
                  <li
                    key={val.id}
                    onClick={() => toggleType(val.id)}
                    className="cursor-pointer"
                  >
                    <p
                      className={
                        typeId === val.id
                          ? "text-primary text-sm font-medium border-b-2 border-primary"
                          : "text-[#96A397] text-sm font-medium"
                      }
                    >
                      {val.title}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </Header>
        </div>

        {farmersLoading ? (
          <div className="my-[20%] flex justify-center items-center">
            <p className="font-medium text-base leading-6 text-primary ">
              Loading...
            </p>
          </div>
        ) : (
          <>
            {typeId === 1 && (
              <>
                <div className="mb-5">
                  <div className="">
                    <div className="">
                      <Header title="Farmer Profile">
                        <div className="flex items-center gap-[8px]">
                          {/* FARMER"S INITIALS */}
                          <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
                            {
                              farmerProfile?.first_name ?
                              <p className="font-medium text-base leading-6 text-white ">
                              {farmerProfile?.first_name?.charAt(0) || "T"}
                              {farmerProfile?.last_name?.charAt(0) || "U"}
                            </p>
                            :
                              <>
                                <Person style={{ fontSize: "22px" }} />
                              </>
                            }
                           
                          </div>

                          <div className="py-6">
                            {
                              farmerProfile?.first_name ?
                              <p className="text-[#344335] font-medium leading-[18px] text-sm">
                              {
                                farmerProfile?.first_name ?
                                farmerProfile?.first_name + " " + farmerProfile?.last_name
                                :
                                ""
                              }
                            </p>
                            :
                            ""

                            }
                           
                            <div
                              className="flex gap-[6px] mt-[4px] cursor-pointer"
                              onClick={() =>
                                handleCopyNumber(
                                  `${
                                    farmerProfile?.phone_number ||
                                    "070 000 0000"
                                  }`
                                )
                              }
                            >
                              <p
                                className={` text-[#4B4E68]
                  font-normal leading-[18px] text-sm`}
                              >
                                {farmerProfile?.phone_number || "070 000 0000"}
                              </p>
                              <ContentCopy
                                className={` text-[#4B4E68]
                  `}
                                style={{ fontSize: "18px" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 gap-y-4">
                          <div className="flex flex-col gap-1">
                            <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                              Gender
                            </p>
                            <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                              {farmerProfile?.gender === "M"
                                ? "Male"
                                : farmerProfile?.gender === "F"
                                ? "Female"
                                : "Others"}
                            </p>
                          </div>

                          <div className="flex flex-col gap-1">
                            <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                              Crop
                            </p>
                            <div>
                              {cropList.length > 0 ? (
                                <div className="flex gap-2 items-center">
                                  {/* Show the first two crops */}
                                  <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                                    {visibleCrops.join(", ")}
                                  </p>

                                  {/* If there are more crops, show the "+X" tooltip */}
                                  {remainingCrops.length > 0 && (
                                    <Tooltip
                                      title={
                                        <div className="p-2 flex justify-center items-center">
                                          <p className="font-semibold text-xs leading-[16px] text-white">
                                            {cropList.join(", ")}
                                          </p>
                                        </div>
                                      }
                                      arrow
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, 8],
                                            },
                                          },
                                        ],
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            backgroundColor: "#000", // Tooltip background color
                                            borderRadius: "8px", // Tooltip border radius
                                          },
                                        },
                                        arrow: {
                                          sx: {
                                            color: "#000", // Arrow color to match background
                                          },
                                        },
                                      }}
                                    >
                                      <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                                        +{remainingCrops.length}
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                // Show "Nill" if crops are null or empty
                                <p className="font-medium text-sm text-[#2E332F]">
                                  Nill
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="flex flex-col gap-1">
                            <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                              Livestock
                            </p>
                            <div>
                              {livestockList.length > 0 ? (
                                <div className="flex gap-2 items-center">
                                  {/* Show the first two crops */}
                                  <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                                    {visibleLivestock.join(", ")}
                                  </p>

                                  {/* If there are more crops, show the "+X" tooltip */}
                                  {remainingLivestock.length > 0 && (
                                    <Tooltip
                                      title={
                                        <div className="p-2 flex justify-center items-center">
                                          <p className="font-semibold text-xs leading-[16px] text-white">
                                            {livestockList.join(", ")}
                                          </p>
                                        </div>
                                      }
                                      arrow
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, 8],
                                            },
                                          },
                                        ],
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            backgroundColor: "#000", // Tooltip background color
                                            borderRadius: "8px", // Tooltip border radius
                                          },
                                        },
                                        arrow: {
                                          sx: {
                                            color: "#000", // Arrow color to match background
                                          },
                                        },
                                      }}
                                    >
                                      <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                                        +{remainingLivestock.length}
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                // Show "Nill" if livestock are null or empty
                                <p className="font-medium text-sm text-[#2E332F]">
                                  Nill
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="flex flex-col gap-1">
                            <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                              Country
                            </p>
                            <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                              {farmerProfile && farmerProfile.country
                                ? farmerProfile.country
                                : "Nill"}
                            </p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                              State
                            </p>
                            <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                              {farmerProfile && farmerProfile.state
                                ? farmerProfile.state
                                : "Nill"}
                            </p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                              District
                            </p>
                            <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                              {farmerProfile && farmerProfile.district
                                ? farmerProfile.district
                                : "Nill"}
                            </p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="flex flex-col gap-1">
                              <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                                Date of Birth
                              </p>
                              <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                                {farmerProfile && farmerProfile.dob
                                  ? farmerProfile.dob
                                  : "Nill"}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col gap-1 ">
                            <div className="flex flex-col gap-1">
                              <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                                Language
                              </p>
                              <p className="font-medium text-sm leading-[18px] capitalize text-[#2E332F] ">
                                {farmerProfile?.language || "Nill"}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <div className="grid grid-cols-3 gap-6 items-center pb-2">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Gender
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      Male
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Crop
                    </p>
                    <div>
                      <div className="flex gap-2 items-center">
                        <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                          Maize, Cassava
                        </p>

                        <Tooltip
                          // style={{ background: "#000" }}
                          title={
                            <div className="p-2 flex justify-center items-center">
                              <p className="font-semibold text-xs leading-[16px] text-white">
                                Maize, Cassava, Corn, Goat, Cow
                              </p>
                            </div>
                          }
                          arrow
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 8], // Adjusts tooltip position if needed
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#000", // Tooltip background color
                                borderRadius: "8px", // Tooltip border radius
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#000", // Arrow color to match background
                              },
                            },
                          }}
                        >
                          <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                            +3
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    Livestock
                  </p>
                  <div>
                    <div className="flex gap-2 items-center">
                      <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                        Cow, Goat
                      </p>

                      <Tooltip
                        // style={{ background: "#000" }}
                        title={
                          <div className="p-2 flex justify-center items-center">
                            <p className="font-semibold text-xs leading-[16px] text-white">
                              Maize, Cassava, Corn, Goat, Cow
                            </p>
                          </div>
                        }
                        arrow
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 8], // Adjusts tooltip position if needed
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#000", // Tooltip background color
                              borderRadius: "8px", // Tooltip border radius
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#000", // Arrow color to match background
                            },
                          },
                        }}
                      >
                        <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                          +3
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    Country
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Kenya
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    State
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Nairobi
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    District
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Nairobi
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Date of Birth
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      12 Dec 1963
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Language
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      English
                    </p>
                  </div>
                </div>
              </div> */}
                      </Header>
                    </div>
                  </div>
                </div>

                <div className="flex gap-2 items-center py-3 px-3 border border-[#ECEFEC] bg-[#FFFFFF] rounded-lg">
                  <img src={sense} alt="Sense Logo" className="" />
                  <p className="font-semibold text-base text-[#344335]">
                    Profile Insights
                  </p>
                </div>

                <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4">
                  <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                    <p className="font-medium text-sm text-[#3E473F]">
                      Profile Summary
                    </p>
                  </div>
                  <div className="bg-[#E1E6E1] h-[1px]"></div>

                  <div className="px-3 py-3 bg-white rounded-b-lg">
                    <p className="font-normal text-sm text-[#3E473F] ">
                     {farmerProfile?.profile_summary || "No conversation has been had yet"}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  
                  {hasFarmCharacteristics && (
                   <div className="border  border-[#E1E6E1] bg-white rounded-lg">
                      <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                        <p className="font-medium text-sm text-[#3E473F]">
                          Farm Characteristics
                        </p>
                      </div>
                      <div className="bg-[#E1E6E1] h-[1px]"></div>

                      <div className="px-3 py-3 bg-white rounded-b-lg">
                      {farmerProfile?.farm_characteristics?.location_details 
                      ?
                        <div>
                          <h6 className="text-sm text-[#5F6D60]">
                            Location details
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.farm_characteristics?.location_details ||
                              "Nill"}
                          </p>
                        </div>: null}

                        {farmerProfile?.farm_characteristics?.farm_size
                        ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Farm size</h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.farm_characteristics?.farm_size ||
                              "Nill"}
                          </p>
                        </div>:null}

                        {farmerProfile?.farm_characteristics?.livestock
                        ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Livestock</h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                          {farmerProfile?.farm_characteristics?.livestock ||
                              "Nill"}
                          </p>
                        </div>: null}

                        {farmerProfile?.farm_characteristics?.farming_methods
                        ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Farming methods
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                          {farmerProfile?.farm_characteristics?.farming_methods ||
                              "Nill"}
                          </p>
                        </div>: null}

                        {farmerProfile?.farm_characteristics?.equipment
                        ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Equipment</h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.farm_characteristics?.equipment ||
                              "Nill"}
                          </p>
                        </div> : null}

                      
                        <div className="grid grid-cols-2 gap-2 mt-3">
                        {farmerProfile?.farm_characteristics?.infrastructure.values
                        ?
                          <div className="">
                            <h6 className="text-sm text-[#5F6D60]">
                              Infrastructure
                            </h6>
                            <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.farm_characteristics?.infrastructure.values ||
                              "Nill"}
                            </p>
                          </div>: null}

                          {farmerProfile?.farm_characteristics?.infrastructure.size
                          ?
                          <div className="">
                            <h6 className="text-sm text-[#5F6D60]">Size</h6>
                            <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.farm_characteristics?.infrastructure.size ||
                              "Nill"}
                            </p>
                          </div>: null}
                        </div>
                      </div>
                    </div>
                  )}

                    {hasBusinessContext && (
                       <div className="border  border-[#E1E6E1] bg-white rounded-lg">
                      <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                        <p className="font-medium text-sm text-[#3E473F]">
                          Business Context
                        </p>
                      </div>
                      <div className="bg-[#E1E6E1] h-[1px]"></div>

                      <div className="px-3 py-3 bg-white rounded-b-lg">
                      {
                      farmerProfile?.business_context?.market_connections?.values
                        ? 
                        <div>
                          <h6 className="text-sm text-[#5F6D60]">
                            Market connections
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.business_context?.market_connections
                              ?.values || "Nill"}
                          </p>
                        </div>: null }

                        {farmerProfile?.business_context?.distribution_channels.values.length
                        ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Distribution channels
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.business_context?.distribution_channels.values.join(
                              ", "
                            ) || "Nill"}
                          </p>
                        </div>: null}

                        {farmerProfile?.business_context
                              ?.processing_capabilities?.values
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Processing capabilities
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                              {farmerProfile?.business_context?.processing_capabilities?.values || "Nill"}
                          </p>
                        </div>: null}

                      
                      {farmerProfile?.business_context?.storage_facilities?.values
                      ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Storage facilities
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                          {farmerProfile?.business_context?.storage_facilities?.values
                           || "Nill"}
                          </p>
                        </div>
                        : null
                          }

                       
                        {farmerProfile?.business_context
                              ?.certifications_held?.values.length 
                              ?
                          <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Certifications held
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.business_context
                              ?.certifications_held?.values || "Nill"}
                          </p>
                        </div>: null}
                      </div>
                    </div>
                      )}

                  {hasKnowledgeAndExperience && (
                      <div className="border  border-[#E1E6E1] bg-white rounded-lg">
                        <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                          <p className="font-medium text-sm text-[#3E473F]">
                            Knowledge and Experience
                          </p>
                        </div>
                        <div className="bg-[#E1E6E1] h-[1px]"></div>

                        <div className="px-3 py-3 bg-white rounded-b-lg">
                        
                        {farmerProfile?.knowledge_and_experience
                                ?.years_farming_experience
                                ?
                          <div>
                            <h6 className="text-sm text-[#5F6D60]">
                              Years of farming experience
                            </h6>
                            <p className="font-medium text-[#3E473F] text-sm">
                              {farmerProfile?.knowledge_and_experience
                                ?.years_farming_experience || "Nill"}
                            </p>
                          </div>: null
                          }

                        
                        {
                              farmerProfile?.knowledge_and_experience
                              ?.technical_expertise_areas
                              ?
                        <div className="mt-3">
                            <h6 className="text-sm text-[#5F6D60]">
                              Technical expertise areas
                            </h6>
                            <p className="font-medium text-[#3E473F] text-sm">
                              {
                                Array.isArray(
                                  farmerProfile?.knowledge_and_experience
                                    ?.technical_expertise_areas
                                )
                                  ? farmerProfile?.knowledge_and_experience?.technical_expertise_areas.join(
                                      ", "
                                    ) || "Nill" // Join the array into a string
                                  : farmerProfile?.knowledge_and_experience?.technical_expertise_areas?.values?.join(
                                      ", "
                                    ) || "Nill" // Access `values` if it's an object
                              }
                            </p>
                          </div>
                          : null }

                          {farmerProfile?.knowledge_and_experience
                                ?.training_education_background?.level
                                ?
                          <div className="mt-3">
                            <h6 className="text-sm text-[#5F6D60]">
                              Training/education background
                            </h6>
                            <p className="font-medium text-[#3E473F] text-sm">
                              {farmerProfile?.knowledge_and_experience
                                ?.training_education_background?.level || "Nill"}
                            </p>
                          </div>
                          : null
                          }

                        {farmerProfile?.knowledge_and_experience
                                ?.technology_adoption_level?.description
                                ?
                          <div className="mt-3">
                            <h6 className="text-sm text-[#5F6D60]">
                              Technology adoption level
                            </h6>
                            <p className="font-medium text-[#3E473F] text-sm">
                              {farmerProfile?.knowledge_and_experience
                                ?.technology_adoption_level?.description ||
                                "Nill"}
                            </p>
                          </div>: null
                          }

                          {farmerProfile?.knowledge_and_experience?.previous_crop_history?.values.length
                          ?
                          <div className="mt-3">
                            <h6 className="text-sm text-[#5F6D60]">
                              Previous crop history
                            </h6>
                            <p className="font-medium text-[#3E473F] text-sm">
                              {farmerProfile?.knowledge_and_experience?.previous_crop_history?.values.join(
                                ", "
                              ) || "Nill"}
                            </p>
                          </div>
                          : null
                          }
                        </div>
                      </div>
                    )}
                

                  
                  {hasTechnicalParameters && (
                    <div className="border  border-[#E1E6E1] bg-white rounded-lg">
                      <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                        <p className="font-medium text-sm text-[#3E473F]">
                          Technical Parameters
                        </p>
                      </div>
                      <div className="bg-[#E1E6E1] h-[1px]"></div>

                      <div className="px-3 py-3 bg-white rounded-b-lg">
                     
                      {farmerProfile?.technical_parameters?.soil_type
                      ?
                        <div>
                          <h6 className="text-sm text-[#5F6D60]">Soil type</h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.technical_parameters?.soil_type ||
                              "Nill"}
                          </p>
                        </div>
                        : null
                        }

                         {farmerProfile?.technical_parameters?.conditions
                              ?.ph_level
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Conditions</h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.technical_parameters?.conditions
                              ?.ph_level || "Nill"}
                          </p>
                        </div>:
                        null
                        }

                        {farmerProfile?.technical_parameters
                              ?.water_sources_and_availability
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Water sources and availability
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.technical_parameters
                              ?.water_sources_and_availability || "Nill"}
                          </p>
                        </div>: null
                        }

                       
                        {farmerProfile?.technical_parameters?.growing_season
                              ?.duration
                              ?
                           <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Growing season information
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.technical_parameters?.growing_season
                              ?.duration || "Nill"}
                          </p>
                        </div>:
                        null
                        }

                          {farmerProfile?.technical_parameters?.yield_data
                            ?.maize ||
                          farmerProfile?.technical_parameters?.yield_data
                            ?.cassava ||
                          farmerProfile?.technical_parameters?.yield_data
                            ?.groundnuts ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Yield data</h6>
                          {farmerProfile?.technical_parameters?.yield_data
                            ?.maize ||
                          farmerProfile?.technical_parameters?.yield_data
                            ?.cassava ||
                          farmerProfile?.technical_parameters?.yield_data
                            ?.groundnuts ? (
                            <>
                              <p className="font-medium text-[#3E473F] text-sm">
                                Maize:{" "}
                                {farmerProfile?.technical_parameters?.yield_data
                                  ?.maize || "Nill"}
                              </p>
                              <p className="font-medium text-[#3E473F] text-sm">
                                Cassava:{" "}
                                {farmerProfile?.technical_parameters?.yield_data
                                  ?.cassava || "Nill"}
                              </p>
                              <p className="font-medium text-[#3E473F] text-sm">
                                Groundnuts:{" "}
                                {farmerProfile?.technical_parameters?.yield_data
                                  ?.groundnuts || "Nill"}
                              </p>
                            </>
                          ) : (
                            <p className="font-medium text-[#3E473F] text-sm">
                              Nill
                            </p>
                          )}
                        </div>:
                        null
                        }

                        {farmerProfile?.technical_parameters
                              ?.chemical_fertilizer_usage
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Chemical/fertilizer usage
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.technical_parameters
                              ?.chemical_fertilizer_usage || "Nill"}
                          </p>
                        </div>
                        : null 
                        }
                      </div>
                    </div>)}

                    {hasAgriculturalPractices && (
                      <div className="border  border-[#E1E6E1] bg-white rounded-lg mt-4">
                      <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                        <p className="font-medium text-sm text-[#3E473F]">
                          Agricultural Practices
                        </p>
                      </div>
                      <div className="bg-[#E1E6E1] h-[1px]"></div>

                      <div className="px-3 py-3 bg-white rounded-b-lg">
                       
                      {farmerProfile?.agricultural_practices
                              ?.cultivation_techniques
                              ?
                        <div>
                          <h6 className="text-sm text-[#5F6D60]">
                            Cultivation techniques
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.agricultural_practices
                              ?.cultivation_techniques || "Nill"}
                          </p>
                        </div>
                        :
                        null
                        }

                        {farmerProfile?.agricultural_practices
                              ?.irrigation_methods
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Irrigation methods
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.agricultural_practices
                              ?.irrigation_methods || "Nill"}
                          </p>
                        </div>
                        :
                        null
                        }

                          {farmerProfile?.agricultural_practices
                              ?.pest_management_approaches 
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Pest management approaches
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.agricultural_practices
                              ?.pest_management_approaches || "Nill"}
                          </p>
                        </div>
                        :
                        null
                        }

                         {farmerProfile?.agricultural_practices
                              ?.soil_management_practices
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Soil management practices
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.agricultural_practices
                              ?.soil_management_practices || "Nill"}
                          </p>
                        </div>
                        :
                        null
                        }

                         {farmerProfile?.agricultural_practices
                              ?.post_harvest_practice
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Harvest practice
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.agricultural_practices
                              ?.post_harvest_practice || "Nill"}
                          </p>
                        </div>
                        : null
                        }

                         {farmerProfile?.agricultural_practices
                              ?.post_harvest_practice 
                              ?
                        <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">
                            Post-harvest practice
                          </h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                            {farmerProfile?.agricultural_practices
                              ?.post_harvest_practice || "Nill"}
                          </p>
                        </div>
                        :
                         null
                         }
                      </div>
                    </div>
                  )}
                
                </div>
              </>
            )}

            {typeId === 2 && (
              <>
                {/* CARD */}
                <div className="flex gap-4">
                  {/* <div className="flex flex-col gap-2 py-4 w-full md:w-[264px] bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4">
              <p className="text-[#2E332F] text-sm">Avg. call duration (min)</p>

              <div>
                <h4 className="text-[#2E332F] font-semibold text-2xl">20</h4>

                <div className="mt-1">
                  <p className="text-primary text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div>
              </div>
            </div> */}

                  <div className="flex flex-col gap-2 py-4 w-full md:w-[264px] bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4">
                    <p className="text-[#2E332F] text-sm">Calls</p>

                    <div>
                      <h4 className="text-[#2E332F] font-semibold text-2xl">
                        {conversationSummaryTotal}
                      </h4>

                      {/* <div className="mt-1">
                  <p className="text-primary text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div> */}
                    </div>
                  </div>
                </div>

                {/* CONVERSATION */}
                <div className="flex border mt-5 border-[#E1E6E1] rounded-lg w-full bg-white ">
                  <div className="w-[25%] border-r border-l-[#E1E6E1] ">
                    <div className="py-4 border-b border-b-[#E1E6E1] px-4 flex gap-2 items-center">
                      <ForumOutlined
                        style={{ color: "#2B8C34", fontSize: "22px" }}
                      />
                      <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                        Conversations Summary
                      </p>
                      <div className="text-[#2E332F] text-xs font-medium px-2 py-1 bg-[#ECEEEC] rounded-[4px]">
                        {conversationSummary?.length}
                      </div>
                    </div>

                    <div className="flex gap-3 items-center h-fit px-4 py-4">
                      <div className="relative flex-grow">
                        <input
                          type="text"
                          className="appearance-none w-full placeholder:text-[#3E473F] 
                          placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] 
                          focus:outline-none focus:shadow-input rounded-lg border border-[#E1E6E1] bg-[#F6F7F6] py-3 px-10"
                          placeholder="Search"
                        />
                        <div className="absolute top-1/2 transform -translate-y-1/2 left-4 cursor-pointer">
                          <Search
                            style={{ color: "#3E473F", fontSize: "15px" }}
                          />
                        </div>
                      </div>
                      <div className="flex px-3 py-3 max-h-[46px] cursor-pointer justify-center items-center bg-[#F6F7F6] border border-[#E1E6E1] rounded-lg">
                        <DateRange
                          style={{ color: "#3E473F", fontSize: "20px" }}
                        />
                      </div>
                    </div>

                    {currentPage === 1  && conversationSummaryLoading ? (
                      <div className="h-[600px] flex mt-[40px] items-center flex-col">
                        <p className="text-sm">Loading...</p>
                      </div>
                    ) : (
                      <div className="flex flex-col overflow-y-auto cursor-pointer gap-2 h-[600px]">
                        {conversationSumm?.map((data, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => setSelectedSummary(data)}
                              className={`p-4  ${
                                selectedSummary?.snippet === data?.snippet
                                  ? "bg-[#E1F7E3]"
                                  : ""
                              } 
                            ${index !== conversationSumm.length - 1 ? "border-b border-[#E1E6E1" : ""}
                              `}
                            >
                              <div className="flex gap-2 cursor-pointer items-center pb-1">
                                <p className="font-medium text-sm leading-[18px] text-[#2E332F] truncate">
                                  {data?.snippet}
                                </p>
                              </div>
                              {/* <p className="font-normal text-sm leading-[20px] text-[#5F6D60] pb-[6px] truncate">
                                {data?.snippet}
                              </p> */}
                              <p className="font-normal text-sm leading-[16px] text-[#5C715E]">
                                {data?.timestamp?.time}{" "}
                                {data?.timestamp?.time && " |"}{" "}
                                {data?.timestamp?.date}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                       {conversationSumm.length < conversationSummaryTotal &&
                      <div className="py-5 border-t border-[#E1E6E1] flex justify-center items-center">
                          <button
                          disabled={loader}
                          onClick={()=>{
                            handleLoadMore();
                            setCurrentPage(currentPage + 1)
                          }}
                            className="text-[#2B8C34] disabled:text-opacity-[0.6] font-medium text-sm">
                            {loader ? "Loading..." : "Load more"}
                           </button>
                      </div>
                      }
                  </div>

                  <div className="w-[75%] select_scroll">
                    {selectedSummary?.farmer?.name !== "" ? (
                      <>
                        <div className="border-b border-b-[#E1E6E1] flex flex-col gap-1 p-3 w-full px-4 ">
                          <p
                            className={`font-normal text-sm leading-[20px] text-[#5F6D60] ${
                              isToggleShowMore ? "" : "truncate"
                            }`}
                          >
                            {selectedSummary?.snippet}
                          </p>

                          <p
                            onClick={() =>
                              setIsToggleShowMore(!isToggleShowMore)
                            }
                            className="font-medium text-xs cursor-pointer leading-[16px] text-primary"
                          >
                            {isToggleShowMore ? "View less" : " Read more"}
                          </p>
                        </div>

                        <div className="flex flex-col gap-[40px] my-6 p-3 overflow-y-auto w-full scroll h-[500px]">
                          {selectedSummary?.conversation_details?.map(
                            (data, index) => {
                              return (
                                <div>
                                  {data?.sender === "AI" ? (
                                    <div
                                      key={index}
                                      className="flex justify-end items-end"
                                    >
                                      <div className="rounded-lg border bg-[#F2FBF3] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                                        <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                                          {data?.answer}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      key={index}
                                      className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] "
                                    >
                                      <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                                        {data?.farmer_question}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {/* EMPTY STATE */}
                        <div className="flex flex-col justify-center items-center h-full w-full">
                          <img
                            src={dialog}
                            alt="dialog icon"
                            className="w-[64px] h-[64px] pb-3 "
                          />
                          <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                            Click on a summary to see the conversation
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default FarmerProfile;
